import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
// import MainPageTitle from 'src/layouts/main-layout/MainPageTitle';
// import MainPageSection from 'src/layouts/main-layout/MainPageSection';
// import { IInformationWidgetField, InformationWidgetFieldTypes } from 'src/components/information-widget';
// import InformationWidget from 'src/components/information-widget/InformationWidget';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { ICustomDropdownOption } from 'src/components/CustomDropdown';
import { toast } from 'react-toastify';
import {
  IInformationWidgetField,
  InformationWidgetFieldTypes
} from 'src/components/widgets/information-widget';
import InformationWidget from 'src/components/widgets/information-widget/InformationWidget';
import { DataService } from 'src/services/data-service';
import { CustomerStatus, IOrder } from '.';
import { getStoreById } from 'src/utils/store-utils';
import Loader from 'src/components/Loader';
import { useParams } from 'react-router';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { formatIntPhoneNumber } from 'src/helpers/formatting-utils';

// Config.ts

interface IDriverDetails {
  name: string;
  mobileNumber: string;
  role: string;
  active: string;
  onlineStatus: string;
}

interface IDriverDetailsApiResponse {
  name: string;
  mobileNumber: string;
  role: string;
  active: boolean;
  onlineStatus: boolean;
}

const fields: IInformationWidgetField[] = [
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'name',
    title: 'Name',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.MobileNumber,
    name: 'mobileNumber',
    title: 'Mobile Number',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'role',
    title: 'Role',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'active',
    title: 'Active',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'onlineStatus',
    title: 'Online Status',
    width: 'third'
  }

  // {
  //     type: InformationWidgetFieldTypes.Date,
  //     name: 'lastPurchaseDate',
  //     title: 'Last Purchase Date',
  //     width: 'third'
  // }
];

const headers: EnhanceTableHeaderTypes[] = [
  { id: 'createdOn', label: 'created On', type: EnhancedTableColumnType.DATE },
  { id: 'address', label: 'Address', type: EnhancedTableColumnType.TEXT },
  {
    id: 'totalCharge',
    label: 'Total Charge',
    type: EnhancedTableColumnType.CURRENCY,
    currencySymbol: 'USD'
  },
  {
    id: 'deliveryCharge',
    label: 'DeliveryCharge',
    currencySymbol: '$',
    type: EnhancedTableColumnType.CURRENCY
  },
  {
    id: 'status',
    label: 'Status',
    chipColors: {
      Ready: { backgroundColor: 'purple', color: 'white' },
      Completed: { backgroundColor: 'green', color: 'white' },
      Cancelled: { backgroundColor: 'red', color: 'white' },
      Pending: { backgroundColor: 'yellow', color: 'white' }
    },
    type: EnhancedTableColumnType.COLORED_CHIP
  },
  {
    id: 'paidToDriver',
    label: 'Paid To Driver',
    chipColors: {
      Yes: { backgroundColor: 'green', color: 'white' },
      No: { backgroundColor: 'red', color: 'white' }
    },
    type: EnhancedTableColumnType.COLORED_CHIP
  },
  {
    id: 'paidToRestaurant',
    label: 'Paid To Restaurant',
    chipColors: {
      Yes: { backgroundColor: 'green', color: 'white' },
      No: { backgroundColor: 'red', color: 'white' }
    },
    type: EnhancedTableColumnType.COLORED_CHIP
  }
];

const generateData = (orders: IOrder[]) => {
  const data: Record<string, Record<string, any>> = {};

  orders.forEach((t) => {
    //  const restaurant = getStoreById(t.storeId);
    data[t.id] = {
      restaurantId: t.restaurantId,
      restaurant: t.restaurant,
      driverId: t.driverId,
      driver: t.driver, // Random total amount
      customer: t.customer, // Random points gained
      contact: t.contact, // Random points redeemed
      totalCharge: t.totalCharge,
      deliveryCharge: t.deliveryCharge,
      address: t.address,
      status: t.status,
      paidToDriver: t.paidToDriver ? 'Yes' : 'No',
      paidToRestaurant: t.paidToRestaurant ? 'Yes' : 'No',
      createdOn: t.createdOn
    };
  });

  return data;
};

const DriverPage: React.FC = () => {
  const [data, setData] = useState<{
    loaded: boolean;
    driverDetails: Record<string, any>;
    orders: Record<string, any>;
  }>({
    loaded: false,
    driverDetails: {},
    orders: {}
  });

  const dispatch = useAppDispatch();

  const { id } = useParams();

  const actions: ITableMenuAction[] = [
    // {
    //     key: "wow",
    //     title: "wow",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => alert("clicked"),
    // },
    // {
    //     key: "wow1",
    //     title: "wow1",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => console.log("hi")
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  const infoActions: ICustomDropdownOption[] = [
    // {
    //     title: "Abbass",
    //     key: "abbass",
    //     onClick: () => toast.info("Hi")
    // }
  ];

  const initilize = async () => {
    const [driverDetails, Orders] = await Promise.all([
      getDriverDetails(),
      getOrders()
    ]);
    if (Orders && driverDetails) {
      setData({
        loaded: true,
        driverDetails: driverDetails,
        orders: Orders
      });
    }
  };

  const getOrders = async () => {
    const result: Record<string, Record<string, any>> = {};
    const response = await DataService.get('/api/Order/get-driver?id=' + id);
    if (response.ok) {
      const result: { data: IOrder[] } = await response.json();
      return generateData(result.data);
    } else {
      toast.error('Something wrong happened!');
    }
    return result;
  };

  const getDriverDetails = async (): Promise<IDriverDetails> => {
    const response = await DataService.get('/api/Driver/get?id=' + id);
    if (response.ok) {
      const result: { data: IDriverDetailsApiResponse } = await response.json();
      // return generateData(result.result);
      return {
        name: result.data.name,
        mobileNumber: result.data.mobileNumber,
        role: result.data.role,
        active: result.data.active ? 'Yes' : 'No',
        onlineStatus: result.data.onlineStatus ? 'Yes' : 'No'
      };
    } else {
      toast.error('Something wrong happened!');
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  if (!data.loaded) {
    return <Loader size={75} />;
  }

  return (
    <div>
      {/* <MainPageTitle title={`Customer Info ${contactDetails.firstName} - ${contactDetails.lastName}`} /> */}
      <InformationWidget
        title="Driver Information"
        fields={fields}
        data={data.driverDetails}
        actions={infoActions}
      />
      {
        <EnhancedTable
          title="Orders"
          header={headers}
          data={data.orders}
          actions={actions}
        />
      }
    </div>
  );
};

export default DriverPage;
