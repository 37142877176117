import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { useEffect, useState } from 'react';
import { INeighborhoodDetails } from '.';
import { ICityDetails } from '../cities';

interface INeighborhoodEdit {
  name: string;
  code: string;
  cityCode: string;
}

interface INeighborhoodEditProps {
  onSuccess: () => void;
  onClose: () => void;
  updateData: INeighborhoodEdit;
}

const EditNeighborhood: React.FC<INeighborhoodEditProps> = ({
  onSuccess,
  onClose,
  updateData
}) => {
  const navigate = useNavigate();

  const [formConfig, setFormConfig] = useState<Record<string, DynamicFieldTypes>>();

  const submit = async (data) => {
    console.log(data);
    const response = await DataService.post(
      'api/Neighborhood/update',
      { ...data },
      undefined
    );

    if (response.ok) {
      toast.success('Success');
      // To reset form
      navigate('/');

      navigate('/neighborhoods');
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  useEffect(() => {
    initialize();
  }, [])

  const initialize = async () => {
    const response = await DataService.get('api/city/all');
    if (response.ok) {
      const res = await response.json();
      const list: INeighborhoodDetails[] = res.data;
      const citiesList: Record<string, string> = {};
      list.forEach(c => {
        citiesList[c.code] = c.name;
      });
      setFormConfig({
        code: {
          type: DynamicField.TEXT,
          required: true,
          name: 'code',
          title: 'Code',
          value: updateData.code,
          disabled: true
        },
        name: {
          type: DynamicField.TEXT,
          required: true,
          name: 'name',
          title: 'Name',
          value: updateData.name,
          disabled: false
        },
        cityCode: {
          type: DynamicField.SELECT,
          required: false,
          name: 'cityCode',
          title: 'City Code',
          value: updateData.cityCode,
          disabled: false,
          items: citiesList
        }
      });
      console.log(updateData.cityCode, citiesList)
    }
  };

  return (
    <>
      <GenericDrawer title={'Edit Neighborhood'} open={true} onClose={onClose}>
        {
          formConfig &&
          <DynamicFormWidget
            title=""
            drawerMode
            fields={formConfig}
            onSubmit={async (e) => {
              return await submit(e);
            }}
          />
        }
      </GenericDrawer>
    </>
  );
};

export default EditNeighborhood;
