import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { useEffect, useState } from 'react';
import { INeighborhoodDetails } from '.';

interface IAddUsersProps {
  onSuccess: () => void;
  onClose: () => void;
}

interface ICityListing {
  name: string;
  code: string;
  active: boolean;
  cityCode: string;
  status: string;
}

let items = {};

const AddCity: React.FC<IAddUsersProps> = ({ onSuccess, onClose }) => {
  const [cities, setCities] = useState<Record<string, ICityListing>>();
  const navigate = useNavigate();
  const formConfig: Record<string, DynamicFieldTypes> = {
    code: {
      type: DynamicField.TEXT,
      required: true,
      name: 'code',
      title: 'Code',
      value: '',
      disabled: false
    },
    name: {
      type: DynamicField.TEXT,
      required: true,
      name: 'name',
      title: 'Name',
      value: '',
      disabled: false
    },
    cityCode: {
      type: DynamicField.SELECT,
      required: true,
      name: 'cityCode',
      title: 'City Code',
      value: '',
      disabled: false,
      items: items
    }
  };

  const submit = async (data) => {
    console.log(data);
    const response = await DataService.post(
      'api/Neighborhood/create',
      { ...data },
      undefined
    );

    if (response.ok) {
      toast.success('Success');
      // To reset form
      navigate('/');

      navigate('/neighborhoods');
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  const initilizeCities = async () => {
    const response = await DataService.get('api/City/all');
    if (response.ok) {
      const res = await response.json();
      const list: INeighborhoodDetails[] = res.data;
      const result: Record<string, ICityListing> = {};

      list.forEach((c) => {
        result[c.code] = {
          name: c.name,
          code: c.code,
          cityCode: c.cityCode,
          active: c.active,
          status: c.active ? 'Active' : 'Inactive'
        };
      });

      setCities(result);
    }
  };

  useEffect(() => {
    initilizeCities();
  }, []);

  useEffect(() => {
    for (const key in cities) {
      console.log(key);
      items[key] = cities[key].name;
    }
    console.log(items, 'items');
  }, [cities]);

  return (
    <>
      <GenericDrawer title={'Add Neighborhood'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddCity;
