import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { Password } from '@mui/icons-material';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
interface IContactEdit {
  id: string;
  name: string;
  mobileNumber: string;
}
interface IEditContactProps {
  onSuccess: () => void;
  onClose: () => void;
  updateDate: IContactEdit;
}

const EditContact: React.FC<IEditContactProps> = ({
  onSuccess,
  onClose,
  updateDate
}) => {
  const formConfig: Record<string, DynamicFieldTypes> = {
    name: {
      type: DynamicField.TEXT,
      required: true,
      name: 'name',
      title: 'Name',
      value: updateDate.name,
      disabled: false
    },
    startsWith: {
      type: DynamicField.PHONENUMBER,
      required: true,
      name: 'mobileNumber',
      title: 'Mobile Number',
      value: updateDate.mobileNumber,
      disabled: false,
      customValidator: (v) => {
        let valueToValidate: string = v || '';
        if (!valueToValidate.startsWith('+')) {
          valueToValidate = '+' + valueToValidate;
        }
        if (!isPossiblePhoneNumber(valueToValidate)) {
          return 'Invalid Mobile';
        }
      }
    }
  };

  const submit = async (data) => {
    if (!(data.mobileNumber as string).startsWith('+')) {
      data.mobileNumber = '+' + data.mobileNumber;
    }

    const response = await DataService.post(
      'api/Contact/update',
      { ...data, id: updateDate.id },
      undefined
    );

    if (response.ok) {
      onSuccess(); // To reset form
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  return (
    <>
      <GenericDrawer title={'Edit Contact'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default EditContact;
