import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import EditCustomer from './edit-customer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { DataService } from 'src/services/data-service';
import { CustomerStatus, ICityDetails } from '.';
import Loader from 'src/components/Loader';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { TableFilterTypes } from 'src/components/enhanced-table/index-filter';
import AddCity from './add-city';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import EditCity from './edit-city';
import AddCityBulk from './ِadd-bulk';

interface ICityListing {
  name: string;
  code: string;
  active: boolean;
  status: string;
}

const CitiesListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const [updateData, setUpdateData] = useState<ICityListing>();
  const [openEditCity, setOpenEditCity] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, ICityListing>>();
  const [openAddCity, setOpenAddCity] = useState<boolean>(false);
  const [openAddBulk, setOpenAddBulk] = useState<boolean>(false);

  const activate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to activate this user?',
      onSubmit: async () => {
        const result = await DataService.post('api/City/activate?id=' + id, {});
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };

    dispatch(OpenConfirmation(confimation));
  };
  const edit = (id: string) => {
    setUpdateData(data[id]);
    setOpenEditCity(true);
  };
  const deactivate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to deactivate this city?',
      onSubmit: async () => {
        const result = await DataService.post(
          '/api/City/deactivate?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };
  const headers: EnhanceTableHeaderTypes[] = [
    { id: 'code', label: 'Code', type: EnhancedTableColumnType.TEXT },
    {
      id: 'name',
      label: 'City',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'status',
      label: 'Status',
      type: EnhancedTableColumnType.COLORED_CHIP,
      chipColors: {
        Active: { backgroundColor: 'green', color: 'white' },
        Inactive: { backgroundColor: 'red', color: 'white' }
      }
    },
    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            activate(id);
          },
          icon: <CheckIcon fontSize="small" />,
          label: 'Activate',
          hidden: (r) => r.status?.toLowerCase() === 'active'
        },
        {
          onClick: (id) => {
            deactivate(id);
          },
          icon: <CancelIcon fontSize="small" />,
          label: 'Deactivate',
          hidden: (r) => r.status?.toLowerCase() === 'inactive'
        },
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        }
      ]
    }
  ];

  const initilize = async () => {
    const response = await DataService.get('api/City/all');
    if (response.ok) {
      const res = await response.json();
      console.log(res);
      const list: ICityDetails[] = res.data;
      const result: Record<string, ICityListing> = {};
      list.forEach((c) => {
        result[c.code] = {
          name: c.name,
          code: c.code,
          active: c.active,
          status: c.active ? 'Active' : 'Inactive'
        };
      });
      setData(result);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    {
      key: 'edit',
      title: 'Edit',
      disabled: (selected: string[]) => false,
      onClick: (selected) => {
        setOpenAddBulk(true);
      }
    }
  ];

  if (!data) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Cities List</title>
      </Helmet>
      <MainPageTitle
        title={'City Listing'}
        action={{
          title: 'Add City',
          onClick: () => {
            setOpenAddCity(true);
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Cities"
        header={headers}
        data={data}
        actions={actions}
        filters={[
          // {
          //     type: TableFilterTypes.SELECT,
          //     name: "firstName",
          //     title: "First Name",
          // },
          // {
          //     type: TableFilterTypes.SELECT,
          //     name: "lastName",
          //     title: "Last Name"
          // },
          {
            type: TableFilterTypes.SELECT,
            name: 'status',
            title: 'Status'
          }
        ]}
      />

      {openAddCity && (
        <AddCity
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddCity(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddCity(false);
          }}
        />
      )}

      {openEditCity && (
        <EditCity
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditCity(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditCity(false);
          }}
          updateData={updateData}
        />
      )}

      {openAddBulk && (
        <AddCityBulk
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddBulk(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddBulk(false);
          }}
        />
      )}
    </div>
  );
};

export default CitiesListing;
