import React, { useEffect, useState } from 'react';
import { DynamicField, DynamicFieldTypes, prepareValuesForSubmission } from '.';
import GenericInput from '../input-components/GenericTextInput';
import GenericNumberInput from '../input-components/GenericNumberInput';
import GenericPhoneInput from '../input-components/GenericPhoneInput';
import GenericSelectInput from '../input-components/GenericSelectInput';
import { isEmpty, isValidNumber } from 'src/helpers/validation-utils';
import MainPageTitle from '../layout-components/main-layout/MainPageTitle';
import { Card } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import GenericButton from '../GenericButton';
import { toast } from 'react-toastify';
import GenericTextAreaInput from '../input-components/GenericTextAreaInput';
import GenericFileInput from '../input-components/GenericFileInput';
import GenericDatePicker from '../input-components/GenericDatePicker';
import { formatDate } from 'src/helpers/formatting-utils';
import dayjs from 'dayjs';
interface IDynamicFormWidgetProps {
  title: string;
  fields: Record<string, DynamicFieldTypes>;
  onSubmit: (values: Record<string, any>) => Promise<boolean>;
  drawerMode?: boolean;
}

interface IDynamicFormState {
  values: Record<string, any>;
  errors: Record<string, string>;
  touched: Record<string, boolean>;
}

const useStyles = makeStyles()((_theme) => ({
  root: {
    padding: '20px 25px'
  },
  drawerRoot: {
    padding: "20px 0",
  },
  leftSection: {
    display: 'flex'
  },
  title: {
    display: 'block',
    marginRight: '15px'
  },
  searchField: {
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    fontSize: '16px',
    padding: '0 0 5px',
    margin: '0 30px 0 0'
  }
}));

const DynamicFormWidget: React.FC<IDynamicFormWidgetProps> = ({
  title,
  fields,
  onSubmit, 
  drawerMode = false,
}) => {
  const [pageState, setPageState] = useState<IDynamicFormState>();

  const { classes } = useStyles();

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const newPageState: IDynamicFormState = {
      values: {},
      errors: {},
      touched: {}
    };

    if (fields) {
      Object.values(fields).forEach((f) => {
        newPageState.values[f.name] = f.value;
        newPageState.errors[f.name] = '';
        newPageState.touched[f.name] = false;
      });
      setPageState(newPageState);
    }
  }, [fields]);

  const onValueChange = (name: string, value: any) => {
    let updatedValue = value;

    const newPageState: IDynamicFormState = {
      ...pageState,
      values: { ...pageState.values, [name]: updatedValue },
      errors: { ...pageState.errors, [name]: '' }
    };
    setPageState(newPageState);
  };

  const onFileValueChange = (name: string, value: string) => {
    const newPageState: IDynamicFormState = {
      ...pageState,
      values: { ...pageState.values, [name]: value },
      errors: { ...pageState.errors, [name]: '' },
      touched: { ...pageState.touched, [name]: true }
    };
    setPageState(newPageState);
  };

  const onBlur = (name: string) => {
    const newPageState: IDynamicFormState = {
      ...pageState,
      touched: { ...pageState.touched, [name]: true },
      errors: { ...pageState.errors, [name]: validateField(name) }
    };
    setPageState(newPageState);
  };

  const validateField = (fieldName: string) => {
    const field = Object.values(fields).find((f) => f.name === fieldName);
    if (field) {
      const fieldValue = pageState.values[field.name];
      if (field.required && isEmpty(fieldValue)) {
        return 'Required';
      }

      if(isEmpty(fieldValue)){
        return "";
      }

      switch (field.type) {
        case DynamicField.TEXT: {
          if (field.regex) {
            if (!field.regex.test(fieldValue)) {
              return `Invalid + ${field.title}`;
            }
          }
          return '';
        }
        case DynamicField.CURRENCY:
        case DynamicField.NUMBER: {
          if (!isValidNumber(fieldValue)) {
            return 'Invalid Number!';
          }

          const fieldNumber = Number(fieldValue);

          if (!isEmpty(field.min)) {
            if (field.min > fieldNumber) {
              return 'Minimum value is ' + field.min;
            }
          }

          if (!isEmpty(field.max)) {
            if (field.max < fieldNumber) {
              return 'Maximum value is ' + field.min;
            }
          }
          return '';
        }
        case DynamicField.FILE: {
            if(fieldValue) {
                if(typeof fieldValue === "string"){
                    if(isEmpty(fieldValue)){
                        return "Required"
                    }
                } else {
                    let fileValue = fieldValue as File;
                    if(field.maxSizeInMbs && field.maxSizeInMbs > 0){
                        if(fileValue.size > field.maxSizeInMbs * 1024 * 1024 * field.maxSizeInMbs) {
                            return `Max file size is ${field.maxSizeInMbs} MB`;
                        }
                    }
                }
            }
            return "";
        }
        case DynamicField.MULTISELECT:
        case DynamicField.CHECKBOXLIST: {
          const value = fieldValue as string[];
          if (!Array.isArray(value)) {
            return 'Invalid';
          }

          if (field.required && value.length === 0) {
            return 'Required';
          }

          if (!isEmpty(field.minOptions)) {
            if (field.minOptions > value.length) {
              return 'Minimum allowed options is ' + field.minOptions;
            }
          }

          if (!isEmpty(field.maxOptions)) {
            if (field.maxOptions < value.length) {
              return 'Maximum allowed options is ' + field.maxOptions;
            }
          }
          return "";
        }
        case DynamicField.DATE: {
          if (!dayjs(fieldValue).isValid()) {
            return 'Invalid Date!';
          }

          var fieldDate = dayjs(fieldValue);

          if (!isEmpty(field.min)) {
            if (fieldDate.isBefore(field.min)) {
              return 'Minimum Date is ' + formatDate(field.min);
            }
          }

          if (!isEmpty(field.max)) {
            if (fieldDate.isAfter(field.max)) {
              return 'Maximum Date is ' + formatDate(field.max);
            }
          }

          return '';
        }
      }

      if(field.customValidator){
        return field.customValidator(fieldValue);

      }
      return '';
    }
    return '';
  };

  const renderField = (field: DynamicFieldTypes) => {
    switch (field.type) {
      case DynamicField.TEXT:
        return (
          <GenericInput
            type={field.inputType || 'text'}
            name={field.name}
            title={field.title}
            value={pageState.values[field.name]}
            onChange={(v) => onValueChange(field.name, v)}
            onBlur={() => onBlur(field.name)}
            error={
              pageState.touched[field.name]
                ? pageState.errors[field.name] || ''
                : ''
            }
            disabled={field.disabled || submitting}
          />
        );
      case DynamicField.TEXTAREA:
        return (
          <GenericTextAreaInput
            name={field.name}
            title={field.title}
            value={pageState.values[field.name]}
            onChange={(v) => onValueChange(field.name, v)}
            onBlur={() => onBlur(field.name)}
            disabled={field.disabled || submitting}
            error={
              pageState.touched[field.name]
                ? pageState.errors[field.name] || ''
                : ''
            }
          />
        );

      case DynamicField.NUMBER:
        return (
          <GenericNumberInput
            name={field.name}
            title={field.title}
            value={pageState.values[field.name]}
            onChange={(v) => onValueChange(field.name, v)}
            onBlur={() => onBlur(field.name)}
            error={
              pageState.touched[field.name]
                ? pageState.errors[field.name] || ''
                : ''
            }
            disabled={field.disabled || submitting}
          />
        );

      case DynamicField.PHONENUMBER:
        return (
          <GenericPhoneInput
            type="text"
            name={field.name}
            title={field.title}
            value={pageState.values[field.name]}
            onChange={(v: string) =>{ 
              let value = v;
              if(!v.startsWith("+")){
                value = "+" + value;
              }
              onValueChange(field.name, value);
            }}
            onBlur={() => onBlur(field.name)}
            error={
              pageState.touched[field.name]
                ? pageState.errors[field.name] || ''
                : ''
            }
            disabled={field.disabled || submitting}
          />
        );
    case DynamicField.DATE:
        return (
          <GenericDatePicker
            name={field.name}
            title={field.title}
            value={pageState.values[field.name]}
            minDate={field.min}
            maxDate={field.max}
            onChange={(v) => onValueChange(field.name, v)}
            onBlur={() => onBlur(field.name)}
            error={
              pageState.touched[field.name]
                ? pageState.errors[field.name] || ''
                : ''
            }
            disabled={field.disabled || submitting}
          />
        );
        
      case DynamicField.SELECT:
        return (
          <GenericSelectInput
            type=""
            name={field.name}
            title={field.title}
            value={pageState.values[field.name]}
            onChange={(v) => onValueChange(field.name, v)}
            error={
              pageState.touched[field.name]
                ? pageState.errors[field.name] || ''
                : ''
            }
            disabled={field.disabled || submitting}
            items={field.items}
          />
        );
      case DynamicField.MULTISELECT:
        return (
          <GenericSelectInput
            type=""
            name={field.name}
            multiple
            title={field.title}
            value={pageState.values[field.name]}
            onChange={(v) => onValueChange(field.name, v)}
            error={
              pageState.touched[field.name]
                ? pageState.errors[field.name] || ''
                : ''
            }
            disabled={field.disabled || submitting}
            items={field.items}
          />
        );

      case DynamicField.FILE:
        return (
          <GenericFileInput
            name={field.name}
            title={field.title}
            value={pageState.values[field.name]}
            onChange={(v) => onFileValueChange(field.name, v)}
            allowedTypes={field.allowedTypes}
            error={
              pageState.touched[field.name]
                ? pageState.errors[field.name] || ''
                : ''
            }
            disabled={field.disabled || submitting}
          />
        );

        return;
    }
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    const newTouched: Record<string, boolean> = {};

    Object.values(fields).forEach((f) => {
      newErrors[f.name] = validateField(f.name);
      newTouched[f.name] = true;
    });

    const newPageState = {
      ...pageState,
      errors: newErrors,
      touched: newTouched
    };

    setPageState(newPageState);
    return Object.values(newErrors).every((e) => isEmpty(e));
  };

  const submit = async () => {
    if (!submitting) {
      setSubmitting(true);
      const isValid = validateForm();

      const dataToSubmit = prepareValuesForSubmission(pageState.values, fields)

      if (isValid) {
        await onSubmit(dataToSubmit);
        setSubmitting(false);
      } else {
        toast.error('Incomplete Form!');
        setSubmitting(false);
      }
    }
  };

  const renderBody = () => {
    return <>
    {Object.values(fields).map((f) => renderField(f))}
        <GenericButton
          type="button"
          // color="primary"
          // variant="contained"
          // className={classes.button}
          onClick={submit}
          disabled={submitting}
          text="Submit"
        />
    </>
  }

  return pageState ? (
    <div>
        {
            title && <MainPageTitle title={title} />
        }
        {
            drawerMode ? renderBody()
            :
            <Card className={drawerMode ? classes.drawerRoot : classes.root}>
                {renderBody()}
            </Card> 
        }
    </div>
  ) : (
    <></>
  );
};

export default DynamicFormWidget;
