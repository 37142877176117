import { BrowserRouter } from 'react-router-dom';
import router from 'src/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { Portal } from './Portal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './main.css';
import ConfirmationBox from './components/ConfirmationBox';
import { useEffect, useState } from 'react';
import { DataService } from './services/data-service';


function App() {
  const [booted, setBooted] = useState<boolean>(false);
  useEffect(() => {
    DataService.setBaseUrl("https://beez-app.azurewebsites.net/");
    setTimeout(() => {
      setBooted(true);
    }, 150)
  }, []);


  if(booted){
    return (
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <BrowserRouter>
            <Portal />
            <ConfirmationBox />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
              theme="light"
            />
          </BrowserRouter>
          {/* {content} */}
        </LocalizationProvider>
      </ThemeProvider>
    );

  }

  return <></>


}
export default App;
