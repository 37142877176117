import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { Password } from '@mui/icons-material';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

interface IAddDriverProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddDriver: React.FC<IAddDriverProps> = ({ onSuccess, onClose }) => {
  const navigate = useNavigate();

  const formConfig: Record<string, DynamicFieldTypes> = {
    name: {
      type: DynamicField.TEXT,
      required: true,
      name: 'name',
      title: 'Name',
      value: '',
      disabled: false
    },
    mobileNumber: {
      type: DynamicField.PHONENUMBER,
      required: true,
      name: 'mobileNumber',
      title: 'Mobile Number',
      value: '',
      disabled: false,
      customValidator: (v) => {
        let valueToValidate: string = v || "";
        if(!valueToValidate.startsWith("+")){
          valueToValidate = "+" + valueToValidate;
        }
        if(!isPossiblePhoneNumber(valueToValidate)){
          return "Invalid Mobile";
        }
      }
    },
    password: {
      type: DynamicField.TEXT,
      required: true,
      name: 'password',
      title: 'Password',
      value: '',
      inputType: 'password',
      disabled: false
    },
    Role: {
      type: DynamicField.SELECT,
      required: true,
      name: 'Role',
      title: 'Role',
      value: '',
      items: { Admin: 'Admin', Driver: 'Driver' },
      disabled: false
    }
  };

  const submit = async (data) => {

    if(!(data.mobileNumber as string).startsWith("+")){
      data.mobileNumber = "+" + data.mobileNumber
    }

    const response = await DataService.post(
      'api/Driver/create',
      { ...data },
      undefined
    );

    if (response.ok) {
      toast.success('Success');
      onSuccess();
      navigate('/drivers');
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  return (
    <>
      <GenericDrawer title={'Add Driver'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddDriver;
