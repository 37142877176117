import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';
import AddResturantGroup from './add-restaurant-group';
import EditRestuarantGroup from './edit-restaurant-group';
import AddRestaurantGroup from './add-restaurant-toGroup';
import AddRestaurantToGroup from './add-restaurant-toGroup';

interface IResturantResponseItem {
  name: string;
  code: string;
}

interface IResturantListing {
  name: string;
  code: string;
}

const ResturantGroupListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, IResturantListing>>();
  const [openAddResturantGroup, setOpenAddResturantGroup] =
    useState<boolean>(false);
  const [openAssignResturantGroup, setOpenAssignResturantGroup] =
    useState<boolean>(false);
  const [updateData, setUpdateData] = useState<IResturantListing>();
  const [openEditResturantGroup, setOpenEditResturantGroup] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const edit = (id: string) => {
    setUpdateData(data[id]);
    setOpenEditResturantGroup(true);
  };

  const openAddResturantGroupf = (id: string) => {
    setUpdateData(data[id]);
    setOpenAssignResturantGroup(true);
  };

  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'name',
      label: 'Name',
      type: EnhancedTableColumnType.Clickable,
      onClick: (id, row) => {
        navigate('/resturants-byGroup/' + id);
      }
    },
    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        },
        {
          onClick: (id) => {
            openAddResturantGroupf(id);
          },
          icon: <AddIcon fontSize="small" />,
          label: 'Add Restaurant Group',
          hidden: (r) => false
        }
      ]
    }
  ];

  const tableFiler: ITableFilterType[] = [
    {
      type: TableFilterTypes.SELECT,
      name: 'status',
      title: 'Status',
      defaultValue: []
    }
  ];

  const initilize = async () => {
    setLoading(true);
    const response = await DataService.get('/api/RestaurantGroup/all');
    if (response.ok) {
      const list: { data: IResturantResponseItem[] } = await response.json();
      console.log(list.data, 'data');
      const result: Record<string, IResturantListing> = {};
      list.data.forEach((c) => {
        result[c.code] = {
          name: c.name,
          code: c.code
        };
      });
      setData(result);
      setLoading(false);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    // {
    //     key: "editcontact",
    //     title: "Edit Contact",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => { console.log("hi") }
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  if (!data || loading) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Restaurant Groups</title>
      </Helmet>
      <MainPageTitle
        title={'Restaurant Groups Listing'}
        action={{
          title: 'Add',
          onClick: () => {
            if (!openAddResturantGroup) {
              setOpenAddResturantGroup(true);
            }
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Restaurants"
        defaultOrder=""
        header={headers}
        data={data}
        filters={tableFiler}
        actions={actions}
      />

      {openAddResturantGroup && (
        <AddResturantGroup
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddResturantGroup(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddResturantGroup(false);
          }}
        />
      )}

      {openAssignResturantGroup && (
        <AddRestaurantToGroup
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAssignResturantGroup(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAssignResturantGroup(false);
          }}
          data={updateData}
        />
      )}

      {openEditResturantGroup && (
        <EditRestuarantGroup
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditResturantGroup(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditResturantGroup(false);
          }}
          updateData={updateData}
        />
      )}
    </div>
  );
};

export default ResturantGroupListing;
