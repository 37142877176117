import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IAddBulkProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddCityBulk: React.FC<IAddBulkProps> = ({ onSuccess, onClose }) => {
  const formConfig: Record<string, DynamicFieldTypes> = {
    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.XLSX, FileType.XLS],
      required: true,
      maxSizeInMbs: 5,
      name: 'file',
      title: 'Neighborhood Bulk File',
      value: '',
      disabled: false
    }
  };

  const submit = async (data) => {
    console.log(data);
    let data2 = {};

    const formData = new FormData();
    const file: File = data.file;
    formData.append('request', JSON.stringify(data2));
    formData.append('formFile', file, file.name);
    const req = await DataService.postForm('/api/City/bulk-add', formData);

    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <>
      <GenericDrawer title={'Add Bulk File'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddCityBulk;
