import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { useEffect, useState } from 'react';
import { INeighborhoodDetails } from '.';
import { useParams } from 'react-router';

interface IAddUsersProps {
  onSuccess: () => void;
  onClose: () => void;
}

interface ICityListing {
  name: string;
  code: string;
  active: boolean;
  cityCode: string;
}

let items = {};

const AddContactAddress: React.FC<IAddUsersProps> = ({
  onSuccess,
  onClose
}) => {
  const [neighborhoods, setNeighborhoods] =
    useState<Record<string, ICityListing>>();
  const { id } = useParams();

  const formConfig: Record<string, DynamicFieldTypes> = {
    description: {
      type: DynamicField.TEXT,
      required: true,
      name: 'description',
      title: 'Description',
      value: '',
      disabled: false
    },

    neighborhoodCode: {
      type: DynamicField.SELECT,
      required: true,
      name: 'neighborhoodCode',
      title: 'Neighborhood Code',
      value: '',
      disabled: false,
      items: items
    }
  };

  const submit = async (data) => {
    console.log(data);
    const response = await DataService.post(
      'api/Contact/create-address',
      { ...data, contactId: id },
      undefined
    );

    if (response.ok) {
      // To reset form
      onSuccess();
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  const initilizeCities = async () => {
    const response = await DataService.get('api/Neighborhood/all');
    if (response.ok) {
      const res = await response.json();
      const list: INeighborhoodDetails[] = res.data;
      const result: Record<string, ICityListing> = {};

      list.forEach((c) => {
        result[c.code] = {
          name: c.name,
          code: c.code,
          cityCode: c.cityCode,
          active: c.active
        };
      });

      setNeighborhoods(result);
    }
  };

  useEffect(() => {
    initilizeCities();
  }, []);

  useEffect(() => {
    for (const key in neighborhoods) {
      console.log(key);
      items[key] = neighborhoods[key].cityCode;
    }
    console.log(items, 'items');
  }, [neighborhoods]);

  return (
    <>
      <GenericDrawer title={'Add Address'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddContactAddress;
