import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import { EnhanceTableHeaderTypes, EnhancedTableColumnType, ITableMenuAction } from 'src/components/enhanced-table';
// import MainPageTitle from 'src/layouts/main-layout/MainPageTitle';
// import MainPageSection from 'src/layouts/main-layout/MainPageSection';
// import { IInformationWidgetField, InformationWidgetFieldTypes } from 'src/components/information-widget';
// import InformationWidget from 'src/components/information-widget/InformationWidget';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { ICustomDropdownOption } from 'src/components/CustomDropdown';
import { toast } from 'react-toastify';
import { IInformationWidgetField, InformationWidgetFieldTypes } from 'src/components/widgets/information-widget';
import InformationWidget from 'src/components/widgets/information-widget/InformationWidget';
import { DataService } from 'src/services/data-service';
import { CustomerStatus, ITransaction } from '.';
import { getStoreById } from 'src/utils/store-utils';
import Loader from 'src/components/Loader';
import { useParams } from 'react-router';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { formatIntPhoneNumber } from 'src/helpers/formatting-utils';

// Config.ts

interface IContactDetails {
    firstName: string;
    lastName: string;
    mobileNumber: string;
    email: string;
    status: string;
    totalPoints: number;
    lifeTimePoints: number;
    date: string;
    country: string;
}

interface IContactDetailApiResponse {
    country: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    mobileNumber: string;
    points: number;
    pointsValue: number;
    lifeTimePoints: number;
    role: number;
    status: number;
}

const fields: IInformationWidgetField[] = [
    {
        type: InformationWidgetFieldTypes.Text,
        name: 'firstName',
        title: 'First Name',
        width: 'third'
    },
    {
        type: InformationWidgetFieldTypes.Text,
        name: 'lastName',
        title: 'Last Name',
        width: 'third'
    },
    {
        type: InformationWidgetFieldTypes.Text,
        name: 'email',
        title: 'Email',
        width: 'third'
    },
    {
        type: InformationWidgetFieldTypes.MobileNumber,
        name: 'mobileNumber',
        title: 'Mobile Number',
        width: 'third'
    },
    {
        type: InformationWidgetFieldTypes.Text,
        name: 'status',
        title: 'Status',
        width: 'third'
    },
    {
        type: InformationWidgetFieldTypes.Datetime,
        name: 'date',
        title: 'Date',
        width: 'third'
    },
    {
        type: InformationWidgetFieldTypes.Text,
        name: 'country',
        title: 'Country',
        width: 'third'
    },
    {
        type: InformationWidgetFieldTypes.Text,
        name: 'totalPoints',
        title: 'Total Points',
        width: 'third'
    },
    {
        type: InformationWidgetFieldTypes.Text,
        name: 'lifeTimePoints',
        title: 'Life Time Points',
        width: 'third'
    },
    // {
    //     type: InformationWidgetFieldTypes.Date,
    //     name: 'lastPurchaseDate',
    //     title: 'Last Purchase Date',
    //     width: 'third'
    // }
];

const headers: EnhanceTableHeaderTypes[] = [
    { id: 'date', label: 'Date', type: EnhancedTableColumnType.DATE },
    { id: 'location', label: 'Location', type: EnhancedTableColumnType.TEXT },
    { id: 'store', label: 'Store', type: EnhancedTableColumnType.TEXT },
    { id: 'totalAmount', label: 'Total Amount', type: EnhancedTableColumnType.CURRENCY },
    { id: 'pointsGained', label: 'Points Gained', type: EnhancedTableColumnType.NUMBER },
    { id: 'pointsRedeemed', label: 'Points Redeemed', type: EnhancedTableColumnType.NUMBER }
];

const generateData = (transactions: ITransaction[]) => {
    const data: Record<string, Record<string, any>> = {};

    transactions.forEach(t => {
        const store = getStoreById(t.storeId);
        data[t.id] = {
            date: t.createdOn,
            location: store.Location,
            store: store.StoreName,
            totalAmount: t.total, // Random total amount
            pointsGained: t.pointsGained, // Random points gained
            pointsRedeemed: t.pointsUsed // Random points redeemed
        };
    });

    return data;
};



const CustomerPage: React.FC = () => {
    const [data, setData] = useState<{loaded: boolean, customerDetails: Record<string, any>, transactions: Record<string, any>}>({
        loaded: false,
        customerDetails: {},
        transactions: {}
    });

    const dispatch = useAppDispatch();


    const { id } = useParams();

    const actions: ITableMenuAction[] = [
        // {
        //     key: "wow",
        //     title: "wow",
        //     disabled: (selected: string[]) => selected.length > 0,
        //     onClick: () => alert("clicked"),
        // },
        // {
        //     key: "wow1",
        //     title: "wow1",
        //     disabled: (selected: string[]) => selected.length === 0,
        //     onClick: (selected) => {

        //         const confirm: IConfirmation = {
        //             open: true,
        //             title: "Hi",
        //             message: "Are you sure?",
        //             onSubmit: () => console.log("hi")
        //         }

        //         dispatch(OpenConfirmation(confirm));
        //     }
        // }
    ];

    const infoActions: ICustomDropdownOption[] = [
        // {
        //     title: "Abbass",
        //     key: "abbass",
        //     onClick: () => toast.info("Hi")
        // }
    ];





    const initilize = async () => {
        const [details, newTransactions] = await Promise.all([getCustomerDetails(), getTransactions()]);
        if(details && newTransactions){

            setData({
                loaded: true,
                customerDetails: details, 
                transactions: newTransactions
            });
        }
    }


    const getTransactions = async () => {
        const result: Record<string, Record<string, any>> = {};
        const response = await DataService.get("/api/Transaction/customer/" + id);
        if (response.ok) {
            const result: { result: ITransaction[] } = await response.json();
            return generateData(result.result);
        } else {
            toast.error("Something wrong happened!");
        }
        return result;
    }

    const getCustomerDetails = async (): Promise<IContactDetails> => {
        const response = await DataService.get("/api/User/id/" + id);
        if (response.ok) {
            const result: { result: IContactDetailApiResponse } = await response.json();
            // return generateData(result.result);
            return {
                firstName: result.result.firstName,
                lastName: result.result.lastName,
                mobileNumber: formatIntPhoneNumber(ensurePlusPrefix(result.result.mobileNumber)),
                email: result.result.email,
                status: CustomerStatus[result.result.status],
                totalPoints: result.result.points,
                date: "-",
                country: result.result.country,
                lifeTimePoints: result.result.lifeTimePoints
            };
        } else {
            toast.error("Something wrong happened!");
        }
    }

    useEffect(() => {
        initilize();
    }, []);

    if (!data.loaded) {

        return <Loader size={75} />
    }


    return (
        <div>
            {/* <MainPageTitle title={`Customer Info ${contactDetails.firstName} - ${contactDetails.lastName}`} /> */}
            <InformationWidget
                title="Customer Information"
                fields={fields}
                data={data.customerDetails}
                actions={infoActions}
            />
            <EnhancedTable
                title="Transactions"
                header={headers}
                data={data.transactions}
                actions={actions}
            />
        </div>
    );
};

export default CustomerPage;
