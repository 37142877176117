import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { useEffect, useState } from 'react';

interface IAddRestaurantGroupProps {
  onSuccess: () => void;
  onClose: () => void;
  data: IGroup;
}

interface IRestaurantListing {
  name: string;
  id: string;
}

interface IGroup {
  name: string;
  code: string;
}

let items = {};

const AddRestaurantToGroup: React.FC<IAddRestaurantGroupProps> = ({
  onSuccess,
  onClose,
  data
}) => {
  const [restaurants, setRestaurants] =
    useState<Record<string, IRestaurantListing>>();
  const navigate = useNavigate();
  const formConfig: Record<string, DynamicFieldTypes> = {
    name: {
      type: DynamicField.TEXT,
      required: true,
      name: 'name',
      title: 'Name',
      value: data.name,
      disabled: true
    },
    restaurant: {
      type: DynamicField.SELECT,
      required: true,
      name: 'restaurant',
      title: 'Restaurant',
      value: '',
      items: items,
      disabled: false
    }
  };

  const submit = async (data1) => {
    console.log(data.code);
    const response = await DataService.post(
      'api/RestaurantGroup/add-restaurant?restaurantId=' +
        data1.restaurant +
        '&groupId=' +
        data.code,
      {},
      undefined
    );

    if (response.ok) {
      // To reset form
      onSuccess();
      navigate('/resturant-groups');
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  const initilizeRestaurants = async () => {
    const response = await DataService.get('api/Restaurant/all');
    if (response.ok) {
      const res = await response.json();
      const list: IRestaurantListing[] = res.data;
      const result: Record<string, IRestaurantListing> = {};

      list.forEach((c) => {
        result[c.id] = {
          name: c.name,
          id: c.id
        };
      });

      setRestaurants(result);
    }
  };

  useEffect(() => {
    initilizeRestaurants();
  }, []);

  useEffect(() => {
    for (const key in restaurants) {
      console.log(key);
      items[key] = restaurants[key].name;
    }
    console.log(items, 'items');
  }, [restaurants]);

  return (
    <>
      <GenericDrawer
        title={'Assign Restaurant to Group'}
        open={true}
        onClose={onClose}
      >
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddRestaurantToGroup;
