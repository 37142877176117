import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IAddDiscountProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddPricingVersion: React.FC<IAddDiscountProps> = ({
  onSuccess,
  onClose
}) => {
  const formConfig: Record<string, DynamicFieldTypes> = {
    file: {
      type: DynamicField.FILE,
      allowedTypes: [FileType.XLS, FileType.XLSX],
      required: true,
      maxSizeInMbs: 5,
      name: 'file',
      title: 'Version File',
      value: '',
      disabled: false
    }
  };

  const submit = async (data) => {
    let data2 = {};

    const formData = new FormData();
    const file: File = data.file;
    formData.append('request', JSON.stringify(data2));
    console.log(file);
    formData.append('file', file, file.name);
    const req = await DataService.postForm(
      '/api/Restaurant/create-pricing-version',
      formData
    );

    if (req.ok) {
      await onSuccess();
      return true;
    } else {
      toast.error('An Error occured!');
    }
    return false;
  };

  return (
    <>
      <GenericDrawer title={'Add Version File'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddPricingVersion;
