import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { useEffect, useState } from 'react';

interface IAddUsersProps {
  onSuccess: () => void;
  onClose: () => void;
  blockedDrivers: [];
}

interface IDriverListing {
  id: string;
  name: string;
  mobileNumber: string;
}
interface IDriverResponse {
  id: string;
  name: string;
  mobileNumber: string;
}

let items = {};

const AddBlockedDriver: React.FC<IAddUsersProps> = ({
  onSuccess,
  onClose,
  blockedDrivers = []
}) => {
  const [allDrivers, setAllDrivers] =
    useState<Record<string, IDriverListing>>();
  const { id } = useParams();
  const navigate = useNavigate();
  const formConfig: Record<string, DynamicFieldTypes> = {
    cityCode: {
      type: DynamicField.SELECT,
      required: true,
      name: 'driver',
      title: 'Driver mobile number',
      value: '',
      disabled: false,
      items: items
    }
  };

  const submit = async (data) => {
    console.log(data);

    const response = await DataService.post(
      'api/Restaurant/add-blocked?restaurantId=' +
        id +
        '&driverId=' +
        data.driver,
      {},
      undefined
    );

    if (response.ok) {
      // To reset form
      onSuccess();
      items = {};
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  function removeItems(array1: any[], array2: any[]): any[] {
    return array1.filter((item) => !array2.includes(item.id));
  }

  const initilizeCities = async () => {
    const response = await DataService.get('api/Driver/all');
    if (response.ok) {
      const res = await response.json();
      const list: IDriverResponse[] = res.data;
      const result: Record<string, IDriverListing> = {};
      console.log(list, 'list');
      console.log(blockedDrivers, 'blocked Drivers');
      const finalresult = removeItems(list, blockedDrivers);
      console.log(finalresult, 'Final ');
      finalresult.forEach((c) => {
        result[c.id] = {
          id: c.id,
          name: c.name,
          mobileNumber: c.mobileNumber
        };
      });
      console.log(result, 'result');
      setAllDrivers(result);
    }
  };

  useEffect(() => {
    initilizeCities();
  }, []);

  useEffect(() => {
    for (const key in allDrivers) {
      console.log(key);
      items[key] = allDrivers[key].mobileNumber + ' - ' + allDrivers[key].name;
    }
    console.log(items, 'items1');
  }, [allDrivers, blockedDrivers]);

  return (
    <>
      <GenericDrawer
        title={'Add Driver To Block List'}
        open={true}
        onClose={onClose}
      >
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddBlockedDriver;
