import { makeStyles } from 'tss-react/mui';
import { Box, Paper, Typography } from '@mui/material';
import CustomDropdown, {
  ICustomDropdownOption
} from 'src/components/CustomDropdown';

interface IMainPageTitleProps {
  title: string;
  actionsTitle?: string;
  actions?: ICustomDropdownOption[];
}

const useStyles = makeStyles()((theme) => {
  return {
    box: {
      margin: '0 0 24px',
      width: '100%'
    },
    root: {
      width: '100%',
      padding: '15px'
    },
    title: {
      // color: "white",
    },
    header: {
      padding: '0 0 13px',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      justifyItems: 'center',
      alignContent: 'center',
      flexDirection: 'row'
    },
    body: {
      minHeight: '150px',
      padding: '15px'
    },
    link: {
      color: 'rgb(157, 164, 174)',
      display: 'block',
      '&:hover': {
        color: 'white'
      }
    }
  };
});

const MainPageSection: React.FC<IMainPageTitleProps> = ({
  title,
  actionsTitle = 'Actions',
  actions = [],
  children
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.box}>
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Typography
            className={classes.title}
            variant="h6"
            id="pageTitle"
            component="div"
          >
            {title}
          </Typography>

          {actions && actions.length > 0 ? (
            <CustomDropdown title={actionsTitle} options={actions} />
          ) : (
            <span />
          )}
        </div>

        <div className={classes.body}>{children}</div>
      </Paper>
    </Box>
  );
};

export default MainPageSection;
