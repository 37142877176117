import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import { CustomerStatus, IDriverDetails } from '.';
import Loader from 'src/components/Loader';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { TableFilterTypes } from 'src/components/enhanced-table/index-filter';
import AddDriver from './add-driver';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import EditDriver from './edit-driver';

interface IDriverListing {
  id: string;
  name: string;
  mobileNumber: string;
  active: boolean;
  role: string;
  status: string;
}

const CitiesListing: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [data, setData] = useState<Record<string, IDriverListing>>();
  const [openAddDriver, setOpenAddDriver] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<IDriverListing>();
  const [openEditDriver, setOpenEditDriver] = useState<boolean>(false);
  const activate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to activate this driver?',
      onSubmit: async () => {
        const result = await DataService.post(
          'api/Driver/activate?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };

  const deactivate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to deactivate this driver?',
      onSubmit: async () => {
        const result = await DataService.post(
          '/api/Driver/deactivate?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };
  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'name',
      label: 'name',
      type: EnhancedTableColumnType.Clickable,
      onClick(id, row) {
        navigate('/drivers/' + id);
      }
    },
    {
      id: 'mobileNumber',
      label: 'phone Number',
      type: EnhancedTableColumnType.PhoneNumber
    },
    {
      id: 'role',
      label: 'Role',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'status',
      label: 'Status',
      type: EnhancedTableColumnType.COLORED_CHIP,
      chipColors: {
        Active: { backgroundColor: 'green', color: 'white' },
        Inactive: { backgroundColor: 'red', color: 'white' }
      }
    },
    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            activate(id);
          },
          icon: <CheckIcon fontSize="small" />,
          label: 'Activate',
          hidden: (r) => r.status?.toLowerCase() === 'active'
        },
        {
          onClick: (id) => {
            deactivate(id);
          },
          icon: <CancelIcon fontSize="small" />,
          label: 'Deactivate',
          hidden: (r) => r.status?.toLowerCase() === 'inactive'
        },
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        }
      ]
    }
  ];
  const edit = (id: string) => {
    setUpdateData(data[id]);
    setOpenEditDriver(true);
  };
  const initilize = async () => {
    const response = await DataService.get('api/Driver/all');
    if (response.ok) {
      const res = await response.json();
      console.log(res);
      const list: IDriverDetails[] = res.data;
      const result: Record<string, IDriverListing> = {};

      list.forEach((c) => {
        result[c.id] = {
          name: c.name,
          id: c.id,
          role: c.role,
          mobileNumber: c.mobileNumber,
          active: c.active,
          status: c.active ? 'Active' : 'Inactive'
        };
      });

      setData(result);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    // {
    //   key: 'editcontact',
    //   title: 'Edit Contact',
    //   disabled: (selected: string[]) => selected.length === 0,
    //   onClick: (selected) => {
    //     const confirm: IConfirmation = {
    //       open: true,
    //       title: 'Hi',
    //       message: 'Are you sure?',
    //       onSubmit: () => {
    //         console.log('hi');
    //       }
    //     };
    //     dispatch(OpenConfirmation(confirm));
    //   }
    // }
  ];

  if (!data) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Driver's List</title>
      </Helmet>
      <MainPageTitle
        title={'Driver Listing'}
        action={{
          title: 'Add Driver',
          onClick: () => {
            setOpenAddDriver(true);
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Drivers"
        header={headers}
        data={data}
        actions={actions}
        filters={[
          {
            type: TableFilterTypes.SELECT,
            name: 'status',
            title: 'Status'
          }
        ]}
      />

      {openAddDriver && (
        <AddDriver
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddDriver(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddDriver(false);
          }}
        />
      )}
      {openEditDriver && (
        <EditDriver
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditDriver(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditDriver(false);
          }}
          updateDate={updateData}
        />
      )}
    </div>
  );
};

export default CitiesListing;
