import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
// import MainPageTitle from 'src/layouts/main-layout/MainPageTitle';
// import MainPageSection from 'src/layouts/main-layout/MainPageSection';
// import { IInformationWidgetField, InformationWidgetFieldTypes } from 'src/components/information-widget';
// import InformationWidget from 'src/components/information-widget/InformationWidget';
import { useAppDispatch } from 'src/redux/hooks';

import { toast } from 'react-toastify';

import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';
import { useParams } from 'react-router';

// Config.ts

interface IRestaurantDetails {
  name: string;
  mobileNumber: string;
  id: string;
  description: string;
}

interface IRestaurantDetailsApiResponse {
  name: string;
  mobileNumber: string;
  description: string;
  id: string;
}

const headers: EnhanceTableHeaderTypes[] = [
  { id: 'name', label: 'Name', type: EnhancedTableColumnType.TEXT },
  {
    id: 'mobileNumber',
    label: 'Mobile Number',
    type: EnhancedTableColumnType.PhoneNumber
  },
  {
    id: 'description',
    label: 'Description',
    type: EnhancedTableColumnType.TEXT
  }
];

const generateData = (restaurants: IRestaurantDetails[]) => {
  const data: Record<string, Record<string, any>> = {};

  restaurants.forEach((t) => {
    //  const restaurant = getStoreById(t.storeId);
    data[t.id] = {
      name: t.name,
      mobileNumber: t.mobileNumber,
      description: t.description,
      id: t.id
    };
  });

  return data;
};

const RestaurantsByGroup: React.FC = () => {
  const [data, setData] = useState<{
    loaded: boolean;
    restaurants: Record<string, any>;
  }>({
    loaded: false,
    restaurants: {}
  });

  const dispatch = useAppDispatch();

  const { id } = useParams();

  const actions: ITableMenuAction[] = [
    // {
    //     key: "wow",
    //     title: "wow",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => alert("clicked"),
    // },
    // {
    //     key: "wow1",
    //     title: "wow1",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => console.log("hi")
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  const initilize = async () => {
    const [restaurants] = await Promise.all([getRestaurants()]);
    if (restaurants) {
      setData({
        loaded: true,
        restaurants: restaurants
      });
    }
  };

  const getRestaurants = async () => {
    const result: Record<string, Record<string, any>> = {};
    const response = await DataService.get(
      '/api/RestaurantGroup/get-restaurants?id=' + id
    );
    if (response.ok) {
      const result: { data: IRestaurantDetails[] } = await response.json();
      return generateData(result.data);
    } else {
      toast.error('Something wrong happened!');
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  if (!data.loaded) {
    return <Loader size={75} />;
  }

  return (
    <div>
      {/* <MainPageTitle title={`Customer Info ${contactDetails.firstName} - ${contactDetails.lastName}`} /> */}

      {
        <EnhancedTable
          title="Restaurants"
          header={headers}
          data={data.restaurants}
          actions={actions}
        />
      }
    </div>
  );
};

export default RestaurantsByGroup;
