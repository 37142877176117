import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { Toast } from 'react-toastify/dist/components';

interface IAddUsersProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddCity: React.FC<IAddUsersProps> = ({ onSuccess, onClose }) => {
  const navigate = useNavigate();

  const formConfig: Record<string, DynamicFieldTypes> = {
    code: {
      type: DynamicField.TEXT,
      required: true,
      name: 'code',
      title: 'Code',
      value: '',
      disabled: false
    },
    email: {
      type: DynamicField.TEXT,

      required: true,
      name: 'name',
      title: 'Name',
      value: '',
      disabled: false
    }
  };

  const submit = async (data) => {
    console.log(data);
    if (data.code.length > 3) {
      //onClose();
      toast.error('Invalid code');
      return false;
    }
    const response = await DataService.post(
      'api/City/create',
      { ...data },
      undefined
    );

    if (response.ok) {
      // To reset form
      onSuccess();
      navigate('/cities');
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  return (
    <>
      <GenericDrawer title={'Add City'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddCity;
