import React from 'react';
import { tss } from 'tss-react';
import GenericInputWrapper from './GenericInputWrapper';
import { TextField } from '@mui/material';
import { PhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import ReactPhoneInput from 'react-phone-input-material-ui';

interface IGenericInputProps {
  title: string;
  type: string;
  value: any;
  onChange: (value: any) => void;
  error?: string;
  name: string;
  onBlur?: (name: string) => void;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

const useStyles = tss.create({
  label: {
    display: 'block'
  },
  input: {
    // width: "100%",
    // border: "1px solid black",
    // borderRadius: "5px",
    // height: "30px",
    // fontSize: "12px",
    // lineHeight: "24px",
    // padding: "0 20px",
    // fontFamily: `"Roboto","Helvetica","Arial",sans-serif`
    width: '100%'
  },
  errorInput: {
    // border: "1px solid red"
  },
  error: {
    // display: "block",
    // color: "red",
  }
});

const GenericPhoneInput: React.FC<IGenericInputProps> = ({
  title,
  type,
  value,
  disabled,
  error,
  size = 'medium',
  onChange,
  name,
  onBlur = () => {}
}) => {
  const { classes, cx } = useStyles();

  return (
    <GenericInputWrapper title={''} error={error} name={name}>
      <ReactPhoneInput
        value={value}
        placeholder=""
        component={TextField}
        inputProps={{
          variant: 'outlined',
          error: !!error,
          size: size,
          helperText: '',
          label: title
        }}
        disabled={disabled}
        inputClass={cx(classes.input, !!error ? classes.errorInput : undefined)}
        onChange={(e) => onChange(e)}
        onBlur={() => onBlur(name)}
        onlyCountries={['lb']}
        country={'lb'}
        countryCodeEditable={false}
      />
    </GenericInputWrapper>
  );
};

export default GenericPhoneInput;
