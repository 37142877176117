import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IAddUsersProps {
  onSuccess: () => void;
  onClose: () => void;
}

const items = { admin: 'Admin', employee: 'Employee' };

const AddUser: React.FC<IAddUsersProps> = ({ onSuccess, onClose }) => {
  const navigate = useNavigate();

  const formConfig: Record<string, DynamicFieldTypes> = {
    username: {
      type: DynamicField.TEXT,
      required: true,
      name: 'username',
      title: 'User name',
      value: '',
      disabled: false
    },
    email: {
      type: DynamicField.TEXT,
      inputType: 'email',
      required: true,
      name: 'email',
      title: 'Email',
      value: '',
      disabled: false
    },

    mobileNumber: {
      type: DynamicField.PHONENUMBER,
      required: true,
      name: 'mobileNumber',
      title: 'Mobile Number',
      value: '',
      disabled: false
    },

    role: {
      type: DynamicField.SELECT,
      required: true,
      name: 'role',
      title: 'Role',
      value: '',
      disabled: false,
      items: items
    },

    password: {
      type: DynamicField.TEXT,
      inputType: 'password',
      required: true,
      name: 'password',
      title: 'Password',
      value: '',
      disabled: false
    }
  };

  const submit = async (data) => {
    console.log(data);
    const response = await DataService.post(
      'api/User/create',
      { ...data },
      undefined
    );

    if (response.ok) {
      toast.success('Success');
      // To reset form
      navigate('/');
      navigate('/users');
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  return (
    <>
      <GenericDrawer title={'Add User'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default AddUser;
