import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
// import MainPageTitle from 'src/layouts/main-layout/MainPageTitle';
// import MainPageSection from 'src/layouts/main-layout/MainPageSection';
// import { IInformationWidgetField, InformationWidgetFieldTypes } from 'src/components/information-widget';
// import InformationWidget from 'src/components/information-widget/InformationWidget';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { ICustomDropdownOption } from 'src/components/CustomDropdown';
import { toast } from 'react-toastify';
import {
  IInformationWidgetField,
  InformationWidgetFieldTypes
} from 'src/components/widgets/information-widget';
import InformationWidget from 'src/components/widgets/information-widget/InformationWidget';
import { DataService } from 'src/services/data-service';
import { CustomerStatus, IAddress } from '.';
import Loader from 'src/components/Loader';
import { useParams } from 'react-router';
import CancelIcon from '@mui/icons-material/Cancel';

import EditIcon from '@mui/icons-material/Edit';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import AddContactAddress from './add-address';
import EditContactAddress from './editContactAddress';
// Config.ts

interface IContactDetails {
  name: string;
  mobileNumber: string;
  id: string;
  contactAddresses: [];
}

interface AddressUpdate {
  id: string;
}

const fields: IInformationWidgetField[] = [
  {
    type: InformationWidgetFieldTypes.Text,
    name: 'name',
    title: 'Name',
    width: 'third'
  },
  {
    type: InformationWidgetFieldTypes.MobileNumber,
    name: 'mobileNumber',
    title: 'Mobile Number',
    width: 'third'
  }

  // {
  //     type: InformationWidgetFieldTypes.Date,
  //     name: 'lastPurchaseDate',
  //     title: 'Last Purchase Date',
  //     width: 'third'
  // }
];

const generateData = (addresses: IAddress[]) => {
  const data: Record<string, Record<string, any>> = {};

  addresses.forEach((t) => {
    //  const restaurant = getStoreById(t.storeId);
    data[t.id] = {
      id: t.id,
      cityName: t.cityName,
      pricingCharge: t.pricingCharge,
      neighborhoodName: t.neighborhoodName,
      addressText: t.addressText // Random total amount
    };
  });

  return data;
};

const ContactAddressListing: React.FC = () => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<{
    loaded: boolean;
    contactDetails: Record<string, any>;
    // orders: Record<string, any>;
  }>({
    loaded: false,
    contactDetails: {}
    // orders: {}
  });
  const [updateData, setUpdateData] = useState<AddressUpdate>();
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);

  const { id } = useParams();

  const actions: ITableMenuAction[] = [
    // {
    //     key: "wow",
    //     title: "wow",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => alert("clicked"),
    // },
    // {
    //     key: "wow1",
    //     title: "wow1",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => console.log("hi")
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  const infoActions: ICustomDropdownOption[] = [
    // {
    //     title: "Abbass",
    //     key: "abbass",
    //     onClick: () => toast.info("Hi")
    // }
  ];

  const initilize = async () => {
    const [contactDetails] = await Promise.all([getContactDetails()]);
    if (contactDetails) {
      setData({
        loaded: true,
        contactDetails: contactDetails
      });
    }
  };
  const edit = (id: string) => {
    setUpdateData({ id: id });
    setOpenEditAddress(true);
  };
  const getContactDetails = async (): Promise<IContactDetails> => {
    const headers: EnhanceTableHeaderTypes[] = [
      {
        id: 'cityName',
        label: 'City Name',
        type: EnhancedTableColumnType.TEXT
      },
      {
        id: 'neighborhoodName',
        label: 'Neighborhood Name',
        type: EnhancedTableColumnType.TEXT
      },
      {
        id: 'addressText',
        label: 'Address Text',
        type: EnhancedTableColumnType.TEXT
      },
      {
        id: 'pricingCharge',
        label: 'Pricing Charge',
        type: EnhancedTableColumnType.CURRENCY,
        currencySymbol: 'USD'
      },
      {
        id: 'actions',
        label: 'Actions',
        type: EnhancedTableColumnType.Action,
        actions: [
          {
            onClick: (id) => {
              deleteItem(id);
            },
            icon: <CancelIcon fontSize="small" />,
            label: 'Delete',
            hidden: (r) => r.status?.toLowerCase() === 'inactive'
          },
          {
            onClick: (id) => {
              edit(id);
            },
            icon: <EditIcon fontSize="small" />,
            label: 'Edit',
            hidden: (r) => false
          }
        ]
      }
    ];

    const response = await DataService.get('/api/Contact/get?id=' + id);
    if (response.ok) {
      const result: { data: IContactDetails } = await response.json();
      // return generateData(result.result);
      return {
        name: result.data.name,
        mobileNumber: result.data.mobileNumber,
        id: result.data.id,
        contactAddresses: result.data.contactAddresses
      };
    } else {
      toast.error('Something wrong happened!');
    }
  };

  const deleteItem = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to delete this Address?',
      onSubmit: async () => {
        const result = await DataService.delete(
          '/api/Contact/delete-address?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };
  const headers: EnhanceTableHeaderTypes[] = [
    { id: 'cityName', label: 'City Name', type: EnhancedTableColumnType.TEXT },
    {
      id: 'neighborhoodName',
      label: 'Neighborhood Name',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'addressText',
      label: 'Address Text',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'pricingCharge',
      label: 'Pricing Charge',
      type: EnhancedTableColumnType.CURRENCY,
      currencySymbol: 'USD'
    },
    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            deleteItem(id);
          },
          icon: <CancelIcon fontSize="small" />,
          label: 'Delete',
          hidden: (r) => r.status?.toLowerCase() === 'inactive'
        },
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        }
      ]
    }
  ];
  useEffect(() => {
    initilize();
  }, []);

  if (!data.loaded) {
    return <Loader size={75} />;
  }

  return (
    <div>
      {/* <MainPageTitle title={`Customer Info ${contactDetails.firstName} - ${contactDetails.lastName}`} /> */}
      <InformationWidget
        title="Contact Information"
        fields={fields}
        data={data.contactDetails}
        actions={infoActions}
      />
      <Helmet>
        <title>Contact's List</title>
      </Helmet>
      <MainPageTitle
        title={'Address Listing'}
        action={{
          title: 'Add Address',
          onClick: () => {
            setOpenAddAddress(true);
          }
        }}
      />
      {
        <EnhancedTable
          title="Addresses"
          header={headers}
          data={
            data.contactDetails.contactAddresses
              ? generateData(data.contactDetails.contactAddresses)
              : {}
          }
          actions={actions}
        />
      }

      {openAddAddress && (
        <AddContactAddress
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddAddress(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddAddress(false);
          }}
        />
      )}
      {openEditAddress && (
        <EditContactAddress
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditAddress(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditAddress(false);
          }}
          updateData={updateData}
        />
      )}
    </div>
  );
};

export default ContactAddressListing;
