import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';

interface IResturantEdit {
  name: string;

  code: string;
}

interface IAddDiscountProps {
  onSuccess: () => void;
  onClose: () => void;
  updateData: IResturantEdit;
}

const EditRestuarantGroup: React.FC<IAddDiscountProps> = ({
  onSuccess,
  onClose,
  updateData
}) => {
  const navigate = useNavigate();
  const formConfig: Record<string, DynamicFieldTypes> = {
    name: {
      type: DynamicField.TEXT,
      required: true,

      name: 'Name',
      title: 'Name',
      value: updateData.name,
      disabled: false
    }
  };

  const submit = async (data) => {
    const response = await DataService.post(
      'api/RestaurantGroup/update',
      { ...data, code: updateData.code },
      undefined
    );

    if (response.ok) {
      // To reset form
      onSuccess();
      navigate('/resturant-groups');
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  return (
    <>
      <GenericDrawer
        title={'Edit Restuarant Group'}
        open={true}
        onClose={onClose}
      >
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default EditRestuarantGroup;
