export interface ICityDetails {
  name: string;
  code: string;
  active: boolean;
  status: string;
}

export enum CustomerStatus {
  Pending,
  Active,
  Inactive,
  Closed
}

export interface ITransaction {
  id: string;
  customerId: string;
  storeId: string;
  total: number;
  pointsGained: number;
  pointsUsed: number;
  createdOn: string;
  extendedData: any;
}
