import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate, useParams } from 'react-router';
import { DataService } from 'src/services/data-service';
import Loader from 'src/components/Loader';
import { ensurePlusPrefix } from 'src/helpers/utils';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import {
  ITableFilterType,
  TableFilterTypes
} from 'src/components/enhanced-table/index-filter';
import AddResturant from './add-resturant';
import EditRestuarant from './edit-Resturant';
import AddBlockedDriver from './add-blockedDrivers';

interface IBlockingResponseItem {
  id: number;
  name: string;
  mobileNumber: string;
  role: string;
  active: boolean;
  onlineStatus: boolean;
}

interface IBlockedListing {
  id: number;
  name: string;
  mobileNumber: string;
  role: string;
  active: string;
  onlineStatus: string;
}

const BlockedDriversListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, IBlockedListing>>();
  const [openAddDriver, setOpenAddDriver] = useState<boolean>(false);
  const [blockedDrivers, setBlockedDrivers] = useState<[]>();
  const navigate = useNavigate();
  const { id } = useParams();

  const unblock = (id1: string) => {
    const filteredIds = blockedDrivers.filter((num) => num != id1);
    console.log(id1, 'id');
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to unblock this Driver?',
      onSubmit: async () => {
        console.log(filteredIds);
        const result = await DataService.post(
          'api/Restaurant/edit-blocked?restaurantId=' + id,
          filteredIds,
          {}
        );
        if (result.ok) {
          initilize();
          console.log(result);
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };

  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'name',
      label: 'Name',
      type: EnhancedTableColumnType.TEXT
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number',
      type: EnhancedTableColumnType.PhoneNumber
    },
    {
      id: 'active',
      label: 'Status',
      type: EnhancedTableColumnType.COLORED_CHIP,
      chipColors: {
        Active: { backgroundColor: 'green', color: 'white' },
        Inactive: { backgroundColor: 'red', color: 'white' }
      }
    },
    {
      id: 'onlineStatus',
      label: 'Online Status',
      type: EnhancedTableColumnType.COLORED_CHIP,
      chipColors: {
        Online: { backgroundColor: 'green', color: 'white' },
        Offline: { backgroundColor: 'red', color: 'white' }
      }
    },
    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            unblock(id);
          },
          icon: <CheckIcon fontSize="small" />,
          label: 'Unblock',
          hidden: (r) => r.status?.toLowerCase() === 'active'
        }
      ]
    }
  ];

  const tableFiler: ITableFilterType[] = [
    {
      type: TableFilterTypes.SELECT,
      name: 'status',
      title: 'Status',
      defaultValue: []
    }
  ];
  function removeItems(array1: any[] = [], array2: any[] = []): any[] {
    return array1.filter((item) => array2.includes(item?.id));
  }
  const initilize = async () => {
    setLoading(true);
    const intialResponse = await DataService.get(
      '/api/Restaurant/get-blocked?id=' + id
    );
    console.log(intialResponse);
    if (intialResponse.ok) {
      const Intiallist: { data: [] } = await intialResponse.json();
      setBlockedDrivers(Intiallist.data);

      const response = await DataService.get('/api/Driver/all');

      if (response.ok) {
        const list: { data: IBlockingResponseItem[] } = await response.json();
        console.log(list.data, 'list');
        console.log(Intiallist.data, 'blocked drivers');

        const filteredList = removeItems(list.data, Intiallist.data);
        const result: Record<string, IBlockedListing> = {};

        filteredList.forEach((c) => {
          result[c.id] = {
            name: c.name,
            id: c.id,
            mobileNumber: c.mobileNumber,
            role: c.role,
            active: c.active ? 'Active' : 'Inactive',
            onlineStatus: c.onlineStatus ? 'Online' : 'Offline'
          };
        });

        setData(result);

        setLoading(false);
      }
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    // {
    //     key: "editcontact",
    //     title: "Edit Contact",
    //     disabled: (selected: string[]) => selected.length === 0,
    //     onClick: (selected) => {
    //         const confirm: IConfirmation = {
    //             open: true,
    //             title: "Hi",
    //             message: "Are you sure?",
    //             onSubmit: () => { console.log("hi") }
    //         }
    //         dispatch(OpenConfirmation(confirm));
    //     }
    // }
  ];

  if (!data || loading) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Blocked Drivers</title>
      </Helmet>
      <MainPageTitle
        title={'Blocked Driver Listing'}
        action={{
          title: 'Add Driver',
          onClick: () => {
            setOpenAddDriver(true);
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Blocked Drivers Listing"
        defaultOrder=""
        header={headers}
        data={data}
        filters={tableFiler}
        actions={actions}
      />

      {openAddDriver && (
        <AddBlockedDriver
          onSuccess={function (): void {
            toast.success('Success!');
            initilize();

            setOpenAddDriver(false);
          }}
          onClose={function (): void {
            setOpenAddDriver(false);
          }}
          blockedDrivers={blockedDrivers}
        />
      )}
    </div>
  );
};

export default BlockedDriversListing;
