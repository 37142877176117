import { Modal } from '@mui/material';
import { DynamicField, DynamicFieldTypes } from 'src/components/dynamic-widget';
import DynamicFormWidget from 'src/components/dynamic-widget/DynamicFormWidget';
import { DataService } from 'src/services/data-service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FileType } from 'src/helpers/file-utils';
import GenericDrawer from 'src/components/drawer/GenericDrawer';
import { on } from 'events';

interface ICityEdit {
  name: string;
  code: string;
  active: boolean;
  status: string;
}

interface IAddUsersProps {
  onSuccess: () => void;
  onClose: () => void;
  updateData: ICityEdit;
}

const EditCity: React.FC<IAddUsersProps> = ({
  onSuccess,
  onClose,
  updateData
}) => {
  const navigate = useNavigate();
  const formConfig: Record<string, DynamicFieldTypes> = {
    code: {
      type: DynamicField.TEXT,
      required: true,
      name: 'code',
      title: 'Code',
      value: updateData.code,
      disabled: true
    },
    email: {
      type: DynamicField.TEXT,
      required: true,
      name: 'name',
      title: 'Name',
      value: updateData.name,
      disabled: false
    }
  };

  const submit = async (data) => {
    console.log(data);
    const response = await DataService.post(
      'api/City/update',
      { ...data },
      undefined
    );

    if (response.ok) {
      // toast.success('Success');
      // To reset form
      onSuccess();
      navigate('/cities');
      return true;
    } else {
      const result: { message: string } = await response.json();
      toast.error(result.message || 'An error occurred!');
      return false;
    }
  };

  return (
    <>
      <GenericDrawer title={'Edit City'} open={true} onClose={onClose}>
        <DynamicFormWidget
          title=""
          drawerMode
          fields={formConfig}
          onSubmit={async (e) => {
            return await submit(e);
          }}
        />
      </GenericDrawer>
    </>
  );
};

export default EditCity;
