import React, { useEffect, useState } from 'react';
import EnhancedTable from 'src/components/enhanced-table/EnhancedTable';
import {
  EnhanceTableHeaderTypes,
  EnhancedTableColumnType,
  ITableMenuAction
} from 'src/components/enhanced-table';
import { useAppDispatch } from 'src/redux/hooks';
import { IConfirmation } from 'src/redux/confirmation/types';
import { OpenConfirmation } from 'src/redux/confirmation/confirmationReducer';
import { Helmet } from 'react-helmet-async';
import MainPageTitle from 'src/components/layout-components/main-layout/MainPageTitle';
import { useNavigate } from 'react-router';
import { DataService } from 'src/services/data-service';
import { CustomerStatus, IContactDetails } from '.';
import Loader from 'src/components/Loader';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { TableFilterTypes } from 'src/components/enhanced-table/index-filter';
import AddContact from './add-contact';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import EditContact from './edit-contact';

interface IContactListing {
  id: string;
  name: string;
  mobileNumber: string;
  contactAddresses: [
    {
      id: number;
      cityName: string;
      neighborhoodName: string;
      addressText: string;
      pricingCharge: number;
    }
  ];
}

const ContactListing: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<Record<string, IContactListing>>();
  const [openAddContact, setOpenAddContact] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<IContactListing>();
  const [openEditContact, setOpenEditContact] = useState<boolean>(false);
  const activate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to activate this driver?',
      onSubmit: async () => {
        const result = await DataService.post(
          'api/Driver/activate?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };

  const deactivate = (id: string) => {
    const confimation: IConfirmation = {
      open: true,
      title: 'Confirmation',
      message: 'Do you want to deactivate this driver?',
      onSubmit: async () => {
        const result = await DataService.post(
          '/api/Driver/deactivate?id=' + id,
          {}
        );
        if (result.ok) {
          initilize();
          toast.success('Success!');
        } else {
          toast.error('An error occured');
        }
      }
    };
    dispatch(OpenConfirmation(confimation));
  };
  const headers: EnhanceTableHeaderTypes[] = [
    {
      id: 'name',
      label: 'name',
      type: EnhancedTableColumnType.Clickable,
      onClick(id, row) {
        navigate('/contacts/' + id);
      }
    },
    {
      id: 'mobileNumber',
      label: 'phone Number',
      type: EnhancedTableColumnType.PhoneNumber
    },

    {
      id: 'actions',
      label: 'Actions',
      type: EnhancedTableColumnType.Action,
      actions: [
        {
          onClick: (id) => {
            deactivate(id);
          },
          icon: <CancelIcon fontSize="small" />,
          label: 'Deactivate',
          hidden: (r) => r.status?.toLowerCase() === 'inactive'
        },
        {
          onClick: (id) => {
            edit(id);
          },
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          hidden: (r) => false
        }
      ]
    }
  ];
  const edit = (id: string) => {
    setUpdateData(data[id]);
    setOpenEditContact(true);
  };
  const initilize = async () => {
    const response = await DataService.get('api/Contact/all');
    if (response.ok) {
      const res = await response.json();
      console.log(res);
      const list: IContactDetails[] = res.data;
      const result: Record<string, IContactListing> = {};

      list.forEach((c) => {
        result[c.id] = {
          name: c.name,
          id: c.id,
          mobileNumber: c.mobileNumber,
          contactAddresses: c.contactAddresses
        };
      });

      setData(result);
    }
  };

  useEffect(() => {
    initilize();
  }, []);

  const actions: ITableMenuAction[] = [
    // {
    //     key: "addcontact",
    //     title: "Add Contact",
    //     disabled: (selected: string[]) => selected.length > 0,
    //     onClick: () => {
    //         setAddContact(true)
    //     },
    // },
    // {
    //   key: 'editcontact',
    //   title: 'Edit Contact',
    //   disabled: (selected: string[]) => selected.length === 0,
    //   onClick: (selected) => {
    //     const confirm: IConfirmation = {
    //       open: true,
    //       title: 'Hi',
    //       message: 'Are you sure?',
    //       onSubmit: () => {
    //         console.log('hi');
    //       }
    //     };
    //     dispatch(OpenConfirmation(confirm));
    //   }
    // }
  ];

  if (!data) {
    return <Loader size={75} />;
  }

  return (
    <div>
      <Helmet>
        <title>Contact's List</title>
      </Helmet>
      <MainPageTitle
        title={'Contact Listing'}
        action={{
          title: 'Add Contact',
          onClick: () => {
            setOpenAddContact(true);
          }
        }}
      />

      {/* <MainPageTitle title="Customers" /> */}

      <EnhancedTable
        title="Contacts"
        header={headers}
        data={data}
        actions={actions}
        filters={[
          {
            type: TableFilterTypes.SELECT,
            name: 'status',
            title: 'Status'
          }
        ]}
      />

      {openAddContact && (
        <AddContact
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenAddContact(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenAddContact(false);
          }}
        />
      )}
      {openEditContact && (
        <EditContact
          onSuccess={function (): void {
            toast.success('Success!');
            setOpenEditContact(false);
            initilize();
          }}
          onClose={function (): void {
            setOpenEditContact(false);
          }}
          updateDate={updateData}
        />
      )}
    </div>
  );
};

export default ContactListing;
